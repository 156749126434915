  .fixed-position-bottom {
      position: fixed;
      bottom: 8px;
  }

  .playlist-card-height-260 {
    height: 260px;
  }

  // design for playlist cards
  .playlist-card {
    .mat-button {
        white-space: normal;
        text-align: left;
        font: inherit;
        font-weight: inherit;
        padding: 0 !important;
        .mat-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media only screen and (min-width: 600px) {
            width: 20ch;
          }
          @media only screen and (min-width: 300px) and (max-width: 350px)  {
            width: 23ch;
          }
          @media only screen and (min-width: 351px) and (max-width: 389px)  {
            width: 27ch;
          }
          @media only screen and (min-width: 390px) and (max-width: 420px)  {
            width: 30ch;
          }
        }
    }
  }

  // to get the dynamic height for playlist data table
  .playlist-fixed-height {
    @media only screen and (max-height: 500px) {
        height: 150px;
    }
    @media only screen and (min-height: 550px) {
        height: 195px;
    }
    @media only screen and (min-height: 630px) {
        height: 250px;
    }
    @media only screen and (min-height: 690px) {
        height: 300px;
    }
    @media only screen and (min-height: 740px) {
        height: 350px;
    }
  }

  .playlist-row-pointer table tbody tr {
    cursor: pointer;
  }
