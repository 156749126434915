/* Contains generic styles that could be used even in non-angular projects */

/* Border-box allows the size definitions of elements to be inclusive of its borders
   Example: If not specified, a 100% width element with a 1px border would exceed the width of its parent and potentially
            cause unexpected behaviour, like unintended scrollbars, to appear */
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

/* For some reason, the body in some browser has a margin, setting this to zero (0) gives more flexibility */
body {
    margin: 0;
}


// Review and remove the following styles if they are not required by ALL applications

/* Additional .caps class to capitalize all letters on an element
    Example: Use class="mat-button all-caps" to create an all-caps Material Button
 */
.all-caps {
    text-transform: uppercase;
}

/* Makes the element use extra available space within a flex container
    Example: Use this on a div in a toolbar to fill the space between menu button and action buttons */
.flex-spacer {
    flex-grow: 1 !important;
}

/* Makes the element a flex container which centers its children both horizontally and vertically */
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
