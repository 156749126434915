#scan-appointment-page #tree_table_widget,
#scan-appoint-cupra-page #tree_table_widget {
  $spans: 0 1 2 3 4 5;
  $levels: 0 1 2 3 4;

  @each $span in $spans {
    .rowspan-#{$span} {
      grid-row: auto / span #{$span};
      height: $span * 80px;
    }
    .colspan-#{$span} {
      grid-column: auto / span #{$span};
    }
  }

  @each $level in $levels {
    .level-#{$level} {
      top: $level * 80px;
    }
  }
}
